import Network from '../../constants/network';
import { GET_RACE_RESULTS } from './actionTypes';
import store from '../store';

export const getRaceResults = (raceId:any, lang:string) => {
   const data = {
      data: {
         type: "race",
         attributes: {
            race_id: raceId
         }
      }
   }
   return async (dispatch: (arg0: { type: string; raceResults: any; }) => void) => {
      try {
         const response = await fetch(Network.backEndApiUrl + 'results?race_id=' + raceId + '&lang=' + (lang) + '&details=1',
            {
               method: "GET",
               headers: {
                   "Content-Type": "application/json"
               }
            })
         const resData = await response.json();
         //console.log("checkResp: " + JSON.stringify(resData, null, 2))
         const raceResults = (resData.data) ? resData.data.map((data:any) => {
            const attributes = data.attributes;
            const startDate = new Date(attributes.start_datetime);
            return {
               birth_year: attributes.birth_year,
               city: attributes.city,
               club: attributes.club,
               finish_datetime: attributes.finish_datetime,
               firstname: attributes.firstname,
               gender: attributes.gender,
               lastname: attributes.lastname,
               nationality: attributes.nationality,
               planned_start_datetime: attributes.planned_start_datetime,
               position: attributes.position,
               start_datetime: startDate.toLocaleDateString('en-CA') + " " + startDate.toLocaleTimeString(),
               time: attributes.time,
               finished_attempts: attributes.finished_attempts
            }
         }) : []
         dispatch({ type: GET_RACE_RESULTS, raceResults: raceResults })
      } catch (err) {
         console.log("DEBUG", `getRaceResults error: ${err}`);
         return;
      }
   }
};