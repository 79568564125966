import { GET_LOCATIONS } from "../actions/actionTypes";
import { Race } from "../../datatypes/types";

// @ts-ignore: dispatch
function LocationsReducer(state: Race[] = null, action) {
    switch (action.type) {
        case GET_LOCATIONS: {
            //console.log("checkIT: " + JSON.stringify(action));
            return action.races;
        }
        default: {
            return state;
        }
    }
};

export default LocationsReducer;