import { GET_ABOUT_US, GET_INFO, GET_ZALAN, GET_AMARU, GET_PESSI } from "./actionTypes";
import { Info } from "../../datatypes/types";
import network from "../../constants/network";

// @ts-ignore: dispatch
export const getInformation = (request:string, lang:string) => async dispatch => {
    //console.log("checkIT " + lang)
    try {
        const response = await fetch(network.backEndApiUrl + request + "?lang=" + lang, {
            method: "GET",
            headers: {
                "Content-Type": "application/json"
            }
        });
        const parsedResponse = await response.json();
        console.log("contryData1 " + JSON.stringify(parsedResponse, null, 2));
        if (parsedResponse.massage) {
            console.log("enter000")
            return;
        }
        //console.log("contryData2 " + parsedResponse.data);
        // @ts-ignore: dispatch
        const info: Info = {
            id: parsedResponse.data.attributes.info_id,
            name: parsedResponse.data.attributes.name,
            text: parsedResponse.data.attributes.text
        };
        console.log("info::: " + JSON.stringify(info, null, 2));
        dispatch({ type: GET_INFO, information: info });
    } catch (err) {
        console.log("error " + err)
        //alert(i18n.t('communicationError', { locale: Localization.locale }));
        //Logger.log("DEBUG", `getCountries error: ${err}`);
        return;
    }
}

// @ts-ignore: dispatch
export const getAboutUs = (request:string, lang:string) => async dispatch => {
    //console.log("enter123 " );
    try {
        const response = await fetch(network.backEndApiUrl + request + "?lang=" + lang, {
            method: "GET",
            headers: {
                "Content-Type": "application/json"
            }
        });
        const parsedResponse = await response.json();
        if (parsedResponse.massage) {
            return;
        }
        //console.log("contryData2 " + JSON.stringify(parsedResponse.data));
        // @ts-ignore: dispatch
        const info: Info = {
            id: parsedResponse.data.attributes.info_id,
            name: parsedResponse.data.attributes.name,
            text: parsedResponse.data.attributes.text
        };
        //console.log("info::: " + JSON.stringify(info, null, 2));
        dispatch({ type: GET_ABOUT_US, aboutUs: info });
    } catch (err) {
        //alert(i18n.t('communicationError', { locale: Localization.locale }));
        //Logger.log("DEBUG", `getCountries error: ${err}`);
        return;
    }
}

// @ts-ignore: dispatch
export const getZalan = (request:string, lang:string) => async dispatch => {
    //console.log("enter123 " );
    try {
        const response = await fetch(network.backEndApiUrl + request + "?lang=" + lang, {
            method: "GET",
            headers: {
                "Content-Type": "application/json"
            }
        });
        const parsedResponse = await response.json();
        if (parsedResponse.massage) {
            return;
        }
        //console.log("contryData2 " + JSON.stringify(parsedResponse.data));
        // @ts-ignore: dispatch
        const info: Info = {
            id: parsedResponse.data.attributes.info_id,
            name: parsedResponse.data.attributes.name,
            text: parsedResponse.data.attributes.text
        };
        //console.log("info::: " + JSON.stringify(info, null, 2));
        dispatch({ type: GET_ZALAN, zalan: info });
    } catch (err) {
        //alert(i18n.t('communicationError', { locale: Localization.locale }));
        //Logger.log("DEBUG", `getCountries error: ${err}`);
        return;
    }
}

// @ts-ignore: dispatch
export const getAmaru = (request:string, lang:string) => async dispatch => {
    try {
        const response = await fetch(network.backEndApiUrl + request + "?lang=" + lang, {
            method: "GET",
            headers: {
                "Content-Type": "application/json"
            }
        });
        const parsedResponse = await response.json();
        if (parsedResponse.massage) {
            return;
        }
        // @ts-ignore: dispatch
        const info: Info = {
            id: parsedResponse.data.attributes.info_id,
            name: parsedResponse.data.attributes.name,
            text: parsedResponse.data.attributes.text
        };
        //console.log("info::: " + JSON.stringify(info, null, 2));
        dispatch({ type: GET_AMARU, amaru: info });
    } catch (err) {
        return;
    }
}

// @ts-ignore: dispatch
export const getPessi = (request:string, lang:string) => async dispatch => {
    try {
        const response = await fetch(network.backEndApiUrl + request + "?lang=" + lang, {
            method: "GET",
            headers: {
                "Content-Type": "application/json"
            }
        });
        const parsedResponse = await response.json();
        if (parsedResponse.massage) {
            return;
        }
        // @ts-ignore: dispatch
        const info: Info = {
            id: parsedResponse.data.attributes.info_id,
            name: parsedResponse.data.attributes.name,
            text: parsedResponse.data.attributes.text
        };
        //console.log("info::: " + JSON.stringify(info, null, 2));
        dispatch({ type: GET_PESSI, pessi: info });
    } catch (err) {
        return;
    }
}