import React, {useState, useEffect} from 'react'
import { Link } from 'react-router-dom'
import { MdFingerprint } from 'react-icons/md'
import { FaBars, FaTimes } from 'react-icons/fa'
import { Button } from './Button'
import './Navbar.scss'
import { IconContext } from 'react-icons/lib'
import {useTranslation} from 'react-i18next'

function Navbar() {
    const [click, setClick] = useState(false)
    const [button, setButton] = useState(true)

    const handeClick = () => setClick(!click)
    const closeMobileMenu = () => setClick(false)

    const showButton = () => {
        if(window.innerWidth <= 960) {
            setButton(false)
        } else {
            setButton(true)
        }
    }
    useEffect(() => {
        showButton();
    }, []);
    window.addEventListener('resize', showButton);

    const {t, i18n} = useTranslation('common');

    return (
        <>
        <IconContext.Provider value={{color: '#fff'}}>
            <div className="navbar">
                <div className="navbar-container container">
                    <Link to='/' className="navbar-logo" onClick={closeMobileMenu}>
                        <img src="./images/logo-white.png"/>
                    </Link>
                    <div className="menu-icon" onClick={handeClick}>
                        {click ? <FaTimes /> : <FaBars />}
                    </div>
                    <ul className={click ? 'nav-menu active' : 'nav-menu'}>
                        <li className="nav-item">
                            <Link to='/' className='nav-links' onClick={closeMobileMenu}>
                                {t('nav-home')}
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link to='/news' className='nav-links' onClick={closeMobileMenu}>
                                {t('nav-news')}
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link to='/contact' className='nav-links' onClick={closeMobileMenu}>
                                {t('nav-contact')}
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link to='/races' className='nav-links' onClick={closeMobileMenu}>
                                {t('nav-races')}
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link to='/about' className='nav-links' onClick={closeMobileMenu}>
                                {t('nav-about')}
                            </Link>
                        </li>
                        {/* <li className="nav-btn">
                            {button ? (
                                <Link to='/sign-up' className="btn-link">
                                    <Button buttonStyle='btn--outline'>
                                        SIGN UP
                                    </Button>
                                </Link>
                            ) : (
                                <Link 
                                    to='/sign-up'
                                    className="btn-link"
                                    onClick={closeMobileMenu}
                                >
                                    <Button buttonStyle='btn--outline' buttonSize='btn--mobile'>
                                        SIGN UP
                                    </Button>
                                </Link>
                            )}
                        </li> */}
                    </ul>
                </div>
            </div>
        </IconContext.Provider>
        </>
    )
}

export default Navbar
