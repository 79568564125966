import React, { Component } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Race } from '../../../datatypes/types'
import { getLocations } from '../../../store/actions/locationActions'
import { RootState } from '../../../store/reducers'
import ContactForm from '../../ContactForm'
import HeroSection from '../../HeroSection'
import RaceItem from '../../RaceItem'
import {homeObjOne, homeObjThree } from './Data'

function Races() {

    const {t, i18n} = useTranslation('common');
    const dispatch = useDispatch();
    const races = useSelector<RootState, Race[]>(state => state.Locations);
    let racesToShow;
    if (races === null) {
        dispatch(getLocations(i18n.language));
    }else {
        //console.log("racesToShow " + JSON.stringify(races, null, 2));
        racesToShow = races.map((race, i) =>  {
             return <RaceItem {...{
                index: race.race_id,
                lightBg:true,
                topLine: 'Open Trail',
                headline: race.name + " " + race.distance + "km",
                buttonLabel: t("race-more-info"),
                buttonData:race,
                //buttonLink: "./raceInfo", //+ race.name.toLowerCase().replace(/\s+/g, ''),
                img: race.image_url,
            }} />
        })
    }
    
    return (
        <>
            {racesToShow}
        </>
    )
    
}

export default Races
