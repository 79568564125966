export const GET_INFO = "GET_INFO";
export const GET_ABOUT_US = "GET_ABOUT_US";
export const GET_ZALAN = "GET_ZALAN";
export const GET_AMARU = "GET_AMARU";
export const GET_PESSI = "GET_PESSI";
export const GET_LOCATIONS = "GET_LOCATIONS";

export const GET_RACE_POINTS = "GET_RACE_POINTS";
export const GET_RACE_RESULTS = "GET_RACE_RESULTS";

//userActions
export const UserActions = {
    SEND_MAIL: "SEND_MAIL"
};