import { GET_LOCATIONS } from "./actionTypes";
import { Info, Race } from "../../datatypes/types";
import network from "../../constants/network";

// @ts-ignore: dispatch
export const getLocations = (lang:string) => async dispatch => {
    //console.log("checkIT " + lang)
    try {
        const response = await fetch(network.backEndApiUrl + "locations?lang=" + lang, {
            method: "GET",
            headers: {
                "Content-Type": "application/json"
            }
        });
        const parsedResponse = await response.json();
        //console.log("contryData1 " + JSON.stringify(parsedResponse, null, 2));
        if (parsedResponse.massage) {
            console.log("enter000")
            return;
        }
        console.log("contryData2:: " + JSON.stringify(parsedResponse.data, null, 2));
        // @ts-ignore: dispatch
        const data:Race[] = parsedResponse.data.map((d)=> {
            return d.attributes.races.map((r:Race) => {
                return r;
            })
        }).flat()
        console.log("contryData3::: " + JSON.stringify(data, null, 2));
        dispatch({ type: GET_LOCATIONS, races: data });
    } catch (err) {
        //alert(i18n.t('communicationError', { locale: Localization.locale }));
        //Logger.log("DEBUG", `getCountries error: ${err}`);
        return;
    }
}