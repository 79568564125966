import './App.scss';
import Navbar from './components/Navbar';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Home from './components/pages/Index/Home';
import {getDefaults, I18nextProvider} from 'react-i18next';
import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import common_en from './translations/en/common.json';
import common_sv from './translations/sv/common.json';
import common_hu from './translations/hu/common.json';
import Footer from './components/pages/Footer/Footer';
import News from './components/pages/News/News';
import Contact from './components/pages/Contact/Contact';
import store from './store/store';
import { Provider as StoreProvider } from 'react-redux';
import TermsAndConditions from './components/pages/Footer/TermsAndConditions';
import Policy from './components/pages/Footer/Privacypolicy';
import Races from './components/pages/Races/Races';
import RaceInfo from './components/pages/RaceInfo/RaceInfo';
import About from './components/pages/About/About';
import ScrollToTop from './components/ScrollToTop';
import Rules from './components/pages/Footer/Rules';

const options = {
  order: ['querystring', 'navigator'],
  lookupQuerystring: 'lng'
}

i18next.use(LanguageDetector).init({
  interpolation: { escapeValue: false },
  detection: options,
  resources: {
    en: {
      common: common_en
    },
    sv: {
      common: common_sv
    },
    hu: {
      common: common_hu
    }
  },
  supportedLngs: ['en', 'sv', 'hu']
});

function App() {
  return (
    <I18nextProvider i18n={i18next}>
      <StoreProvider store={store}>
      <Router>
        <ScrollToTop/>
        <Navbar />
        <Switch>
          <Route path='/' exact component={Home}/>
          <Route path='/news' exact component={News}/>
          <Route path='/contact' exact component={Contact}/>
          <Route path='/races' exact component={Races}/>
          <Route path='/raceInfo' exact component={RaceInfo}/>
          <Route path='/about' exact component={About}/>
          <Route path='/termsAndCondition' exact component={TermsAndConditions}/>
          <Route path='/policy' exact component={Policy}/>
          <Route path='/rules' exact component={Rules}/>
        </Switch>
        <Footer />
      </Router>
      </StoreProvider>
    </I18nextProvider>
  );
}

export default App;